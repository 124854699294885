import * as prismic from '@prismicio/client'
import { ClientConfig } from '@prismicio/client'
import { LinkResolverFunction } from '@prismicio/helpers'
import { CreateClientConfig, enableAutoPreviews } from '@prismicio/next'
import { FilledLinkToDocumentField } from '@prismicio/types'

import { getLocaleCodeFromPrismicLang } from 'utils/prismic'

import sm from '../../sm.json'

export const endpoint = sm.apiEndpoint
export const repositoryName = prismic.getRepositoryName(endpoint)

const baseLinkResolver = (
  doc: FilledLinkToDocumentField,
  withLocale: boolean
) => {
  switch (doc.type) {
    case 'home':
      return '/'
    case 'page':
      if (doc.uid === 'home') {
        return '/'
      }
      return withLocale
        ? `/${getLocaleCodeFromPrismicLang(doc.lang)}/${doc.uid}`
        : `/${doc.uid}`
    case 'communitypost':
      if (doc.uid === 'home') {
        return '/'
      }
      return withLocale
        ? `/${getLocaleCodeFromPrismicLang(doc.lang)}/community/${doc.uid}`
        : `/community/${doc.uid}`
    case 'job_ads_page':
      return doc.lang === 'en-gb' ? '/job-ads' : '/darbo-skelbimai'
    default:
      return ''
  }
}

// Update the Link Resolver to match your project's route structure
export const linkResolver: LinkResolverFunction = (doc) =>
  baseLinkResolver(doc, false)

export const linkResolverWithLocale: LinkResolverFunction = (doc) =>
  baseLinkResolver(doc, true)

// This factory function allows smooth preview setup
export function createClient({
  previewData,
  req,
  ...clientConfig
}: ClientConfig & CreateClientConfig = {}) {
  const client = prismic.createClient(endpoint, clientConfig ?? {})
  enableAutoPreviews({
    client,
    previewData: previewData,
    req: req
  })

  return client
}

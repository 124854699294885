import { IShortUserInfo } from 'data-transfers/dto'
import { MessageType, SystemMessage } from 'enums'
import {
  getDatabase,
  onDisconnect,
  onValue,
  ref,
  set,
  Unsubscribe
} from 'firebase/database'
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  DocumentData,
  documentId,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where
} from 'firebase/firestore'

import {
  IMessageAttributes,
  IMessageCreateProps,
  IMessageProps
} from 'components/Messages/components/MessageItem/types'
import {
  ICreateRoomProps,
  IRoomFullInfoProps,
  IRoomProps,
  IUserOnline,
  IUsersOnline
} from 'components/Messages/components/RoomList/types'

import { notifyRecipientAboutMessage } from 'hooks/useMessage/message.service'

import { chunk } from 'utils/array.util'
import firebaseApp from 'utils/firebase'
import messageUtil from 'utils/message.util'
import { isOrgRole } from 'utils/role.util'
import { UserRole } from 'constants/auth'
import {
  isOfflineForDatabase,
  isOfflineForFirestore,
  isOnlineForDatabase,
  isOnlineForFirestore
} from 'constants/firebase'

import { IMissedMessageServiceRequest } from '../components/Messages/hooks/types'

const ROOT_DIR = 'chats'
const ROOT_DIR_ONLINE = 'user_online'
const NOTIFICATIONS = 'notifications'
export const firestore = getFirestore(firebaseApp)

export const realtimeDatabase = getDatabase(firebaseApp)
const infoConnectedRef = ref(realtimeDatabase, '.info/connected')

// Considerable important thing
const generateDocId = ({
  orgUserId,
  candidateId
}: {
  orgUserId: string
  candidateId: string
}) => `${orgUserId}_${candidateId}`

const createMessage = async ({
  roomId,
  time,
  senderRole,
  messageProps
}: {
  roomId: string
  time: number
  senderRole?: UserRole
  messageProps: IMessageCreateProps
}) => {
  const newMessage = collection(firestore, ROOT_DIR, roomId, 'messages')
  const document = await addDoc(newMessage, messageProps)

  const roomData: DocumentData = {
    lastSentMessageDate: time,
    lastMessageText: messageProps.message,
    lastSystemMessage: messageProps.systemMessage,
    lastSenderId: messageProps.senderId,
    chatStatusOrg: 'active',
    chatStatusCandidate: 'active'
  }

  if (messageProps.type === MessageType.General) {
    roomData.isReadLastMessage = false
    roomData.hadCommunication = true
  }
  if (isOrgRole(senderRole)) {
    roomData.lastSentByOrg = serverTimestamp()
  }

  await updateDoc(doc(firestore, ROOT_DIR, roomId), roomData)

  return document.id
}

const updateMessage = async ({
  roomId,
  messageProps
}: {
  roomId: string
  messageProps: IMessageAttributes
}) => {
  const { id, ...values } = messageProps

  if (id) {
    const messageCollection = collection(
      firestore,
      ROOT_DIR,
      roomId,
      'messages'
    )
    await updateDoc(doc(messageCollection, id), { ...values })

    const roomRef = doc(collection(firestore, ROOT_DIR), roomId)
    const room = await getDoc(roomRef)

    if (room.data()?.lastSentMessageDate === messageProps.createdAt) {
      const roomData: DocumentData = {
        lastMessageText: messageProps.message
      }

      await updateDoc(doc(firestore, ROOT_DIR, roomId), roomData)
    }
  }
}

const fetchMessages = ({
  roomId,
  cb
}: {
  roomId: string
  cb: (val: IMessageProps[]) => void
}) => {
  const q = query(
    collection(firestore, ROOT_DIR, roomId, 'messages'),
    orderBy('createdAt', 'desc')
  )
  return onSnapshot(q, ({ docs }) => {
    const arr = docs.map((message) => ({
      ...message.data(),
      id: message.id
    })) as IMessageAttributes[]

    const messageObjs: Record<string, IMessageAttributes> = arr.reduce(
      (acc, item) => {
        return {
          ...acc,
          [item.id]: item
        }
      },
      {}
    )

    const messages: IMessageProps[] = arr
      .reduce((acc, item) => {
        return [
          ...acc,
          {
            ...item,
            reply: item.replyMessageId
              ? messageObjs?.[item.replyMessageId] ?? null
              : null
          }
        ]
      }, [] as IMessageProps[])
      .sort((a, b) => b.createdAt - a.createdAt)

    if (
      messages[messages.length - 2]?.systemMessage === SystemMessage.NewMatch &&
      messages[messages.length - 1]?.systemMessage === SystemMessage.NewMatch
    ) {
      messages.pop()
    }

    cb(messages)
  })
}

const createRoom = async ({
  dataProps: props,
  message
}: {
  dataProps: ICreateRoomProps
  message?: IMessageCreateProps
}) => {
  const { isOpen, orgId, orgUserId, candidateId, hadCommunication } = props

  const room: ICreateRoomProps = {
    isOpen,
    orgId,
    orgUserId,
    candidateId,
    hadCommunication,
    chatStatusCandidate: 'active',
    chatStatusOrg: 'active'
  }

  const docId = generateDocId({ orgUserId, candidateId })

  await setDoc(doc(firestore, ROOT_DIR, docId), room)

  if (message) {
    await createMessage({
      roomId: docId,
      time: Date.now(),
      messageProps: message
    })
  }
}

const fetchUsersOnline = ({
  ids,
  callback
}: {
  ids: string[]
  callback: (val: IUsersOnline) => void
}) => {
  //firebase limit for batches - 10
  const batches = chunk(ids, 10)

  const unsubscribes = batches.map((batch) => {
    const q = query(
      collection(firestore, ROOT_DIR_ONLINE),
      where(documentId(), 'in', batch)
    )

    return onSnapshot(q, ({ docs }) => {
      const loadedUsersOnline = docs.reduce<IUsersOnline>((acc, item) => {
        return { ...acc, [item.id]: item.data() as IUserOnline }
      }, {})

      callback(loadedUsersOnline)
    })
  })

  const unsubscribe: Unsubscribe = () =>
    unsubscribes.forEach((unsubscribe) => {
      unsubscribe()
    })

  return unsubscribe
}
const fetchUserOnline = ({
  id,
  callback
}: {
  id: string
  callback: (val?: IUserOnline) => void
}) => {
  const userOnlineRef = doc(firestore, ROOT_DIR_ONLINE, id)

  return onSnapshot(userOnlineRef, (snapshot) => {
    const data = snapshot.data() as IUserOnline | undefined

    callback(data)
  })
}

const openChatRoom = async (
  me: IShortUserInfo,
  contact: IShortUserInfo,
  myRole: UserRole,
  myEmail?: string
) => {
  let room: IRoomFullInfoProps
  if (isOrgRole(myRole)) {
    room = {
      id: '',
      employerName: me.fullName ?? 'Owner',
      companyName: me.companyName ?? '',
      orgAvatar: me.avatarUrl ?? '',
      candidateName: contact?.fullName ?? '',
      avatarCandidate: contact?.avatarUrl ?? '',
      orgUserId: me.id,
      candidateId: contact?.id ?? '',
      orgId: me.id,
      isOpen: true,
      hadCommunication: false,
      isDataLoading: false
    }
  } else {
    room = {
      id: '',
      employerName: contact?.fullName ?? contact?.companyName ?? '',
      companyName: contact?.companyName ?? '',
      orgAvatar: contact?.avatarUrl ?? '',
      candidateName: me.fullName ?? myEmail ?? 'Me',
      avatarCandidate: me.avatarUrl,
      orgUserId: contact?.id ?? '',
      candidateId: me.id,
      orgId: contact?.organisationId ?? '',
      isOpen: true,
      hadCommunication: false,
      isDataLoading: false
    }
  }

  const result = await createIfNotExist({
    dataProps: room
  })

  return { ...room, ...result }
}
const createIfNotExist = async ({
  dataProps: props
}: {
  dataProps: ICreateRoomProps
}) => {
  const { candidateId, orgUserId } = props

  const docSnap = await getDoc(
    doc(firestore, ROOT_DIR, generateDocId({ orgUserId, candidateId }))
  )
  if (!docSnap.exists()) {
    await createRoom({
      dataProps: props
    })
  }

  const room = { ...docSnap.data(), id: docSnap.id } as IRoomProps
  return room
}
const deleteUserNotification = async (
  userId: string,
  notificationUserId: string
) => {
  const userOnlineNotificationCol = collection(
    firestore,
    ROOT_DIR_ONLINE,
    userId,
    NOTIFICATIONS
  )

  await deleteDoc(doc(userOnlineNotificationCol, notificationUserId))

  const q = query(
    userOnlineNotificationCol,
    orderBy('sendDate', 'desc'),
    limit(1)
  )

  const [oldestNotificationDoc] = (await getDocs(q)).docs
  const oldestNotification = oldestNotificationDoc?.data().sendDate ?? null

  const userOnlineRef = doc(firestore, ROOT_DIR_ONLINE, userId)
  setDoc(userOnlineRef, { oldestNotification }, { merge: true })
}

const updateRoom = async ({
  dataProps: props
}: {
  dataProps: Partial<IRoomProps>
}) => {
  const { id, ...values } = props

  if (id) {
    await updateDoc(doc(firestore, ROOT_DIR, id), { ...values })
  }
}

const fetchRoomByIds = async ({
  candidateId,
  orgUserId
}: {
  candidateId: string
  orgUserId: string
}) => {
  const roomRef = doc(
    firestore,
    ROOT_DIR,
    generateDocId({ orgUserId, candidateId })
  )

  return await getDoc(roomRef)
}
const fetchRoomById = ({
  roomId,
  cb
}: {
  roomId: string
  cb: (room: IRoomProps) => void
}) => {
  const roomRef = doc(firestore, ROOT_DIR, roomId)

  return onSnapshot(roomRef, (room) => {
    const fullRoom = { ...room.data(), id: roomId } as IRoomProps
    cb(fullRoom)
  })
}

const fetchRooms = ({
  userRole,
  userId,
  cb
}: {
  userRole: UserRole
  userId: string
  cb: (val: IRoomProps[]) => void
}) => {
  const roomsRef = collection(firestore, ROOT_DIR)

  const { fieldName } = messageUtil.getSearchedByUserField(userRole, {
    candidateId: '',
    orgUserId: ''
  })

  const q = query(
    roomsRef,
    where(fieldName, '==', userId),
    where('hadCommunication', '==', true)
  )

  return onSnapshot(q, async (rooms) => {
    const items = rooms.docs
      .map(
        (room) =>
          ({
            ...room.data(),
            id: room.id
          } as IRoomProps)
      )
      .sort(
        (a, b) =>
          Number(b.lastSentMessageDate ?? 0) -
          Number(a.lastSentMessageDate ?? 0)
      )

    cb(items)
  })
}

const notifyRecipient = async ({
  recipientId
}: IMissedMessageServiceRequest) => {
  await notifyRecipientAboutMessage({ recipientId })
}

const trackUserPresence = (userId: string) => {
  const userStatusDatabaseRef = ref(realtimeDatabase, `/status/${userId}`)
  const userOnlineFirestoreRef = doc(firestore, `/${ROOT_DIR_ONLINE}/${userId}`)

  return onValue(infoConnectedRef, async (snapshot) => {
    // If we're not currently connected, don't do anything.
    if (snapshot.val() == false) {
      await setDoc(userOnlineFirestoreRef, isOfflineForFirestore, {
        merge: true
      })
      return
    }

    // If we are currently connected, then use the 'onDisconnect()'
    // method to add a set which will only trigger once this
    // client has disconnected by closing the app,
    // losing internet, or any other means.
    onDisconnect(userStatusDatabaseRef)
      .set(isOfflineForDatabase)
      .then(async () => {
        // The promise returned from .onDisconnect().set() will
        // resolve as soon as the server acknowledges the onDisconnect()
        // request, NOT once we've actually disconnected:
        // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

        // We can now safely set ourselves as 'online' knowing that the
        // server will mark us as offline once we lose connection.
        await set(userStatusDatabaseRef, isOnlineForDatabase)

        // We'll also add Firestore set here for when we come online.
        await setDoc(userOnlineFirestoreRef, isOnlineForFirestore, {
          merge: true
        })
      })
  })
}

const setUserOffline = async (userId: string) => {
  const userStatusDatabaseRef = ref(realtimeDatabase, `/status/${userId}`)
  const userOnlineFirestoreRef = doc(firestore, `/${ROOT_DIR_ONLINE}/${userId}`)

  await onDisconnect(userStatusDatabaseRef).cancel()
  await setDoc(userOnlineFirestoreRef, isOfflineForFirestore, {
    merge: true
  })
  await set(userStatusDatabaseRef, isOfflineForDatabase)
}

const actions = {
  fetchMessages,
  fetchRooms,
  createMessage,
  updateMessage,
  updateRoom,
  fetchRoomById,
  fetchRoomByIds,
  openChatRoom,
  fetchUsersOnline,
  fetchUserOnline,
  notifyRecipient,
  trackUserPresence,
  setUserOffline,
  deleteUserNotification
}

export default actions

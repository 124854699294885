export enum SubscriptionStatus {
  NotPaid,
  NotStarted,
  Active,
  Expired,
  Canceled
}

export enum SubscriptionNames {
  Free = 'free',
  Trial = 'trial',
  Basic = 'basic',
  Pro = 'pro'
}

export const paidPlans = ['trial', 'basic', 'pro'] as const
export const allPlans = [...paidPlans, 'free', 'none'] as const
export const times = ['fortnight', 'monthly', 'yearly'] as const

export const periodMap: Record<number, typeof times[number]> = {
  0: 'fortnight',
  1: 'monthly',
  12: 'yearly'
}

import { IShortUserInfo } from 'data-transfers/dto'
import {
  IMissedMessageRequest,
  IUserMatchesRequest,
  IUserMatchRequest,
  IUserRepresentationRequest
} from 'data-transfers/requests'
import {
  IMessagesContactResponse,
  IMessagesContactsResponse,
  IMessageTokenResponse
} from 'data-transfers/responses'
import { RepresentationFor } from 'enums'

import { authClient } from 'utils'

export const getUserRepresentation = async (
  { userId }: IUserRepresentationRequest,
  type: RepresentationFor
) => {
  const { data } = await authClient.get<IShortUserInfo>(
    `/messaging/representations/${type}/${userId}`
  )

  return data
}

export const getMatchesContacts = async (params: IUserMatchesRequest) => {
  const { data } = await authClient.get<IMessagesContactsResponse>(
    '/messaging/contacts',
    {
      params
    }
  )

  return data
}
export const getMatchContact = async ({ userId }: IUserMatchRequest) => {
  const { data } = await authClient.get<IMessagesContactResponse>(
    `/messaging/contacts/${userId}`
  )

  return data
}

export const firebaseToken = async (): Promise<IMessageTokenResponse> => {
  const { data } = await authClient.get<IMessageTokenResponse>(
    '/messaging/token'
  )

  return data
}

export const notifyRecipientAboutMessage = async (
  props: IMissedMessageRequest
) => {
  await authClient.post('/messaging/notifications', props)

  return
}

import { ComponentPropsWithoutRef, FC } from 'react'

import { IconEdit, IconPlus } from 'icons'

const buttonVariants = ['add', 'edit'] as const
type IVariantTypes = typeof buttonVariants[number]

export const variantIcons: {
  [key in IVariantTypes]: FC<{ className?: string }>
} = {
  add: IconPlus,
  edit: IconEdit
}

export interface ISectionEditButtonProps
  extends Omit<ComponentPropsWithoutRef<'button'>, 'children'> {
  variant?: IVariantTypes
  isPlain?: boolean
  showText?: boolean
  arrowIcon?: boolean
}

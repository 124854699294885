import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { SessionProvider } from 'next-auth/react'
import { appWithTranslation } from 'next-i18next'
import { useState } from 'react'
import { PrismicPreview } from '@prismicio/next'
import { PrismicProvider } from '@prismicio/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import AuthGates from 'components/AuthGates'
import FirebaseAuth from 'components/FirebaseAuth'
import { Layout } from 'components/Layouts'
import MetaTags from 'components/MetaTags/MetaTags'
import SEOMarkupSchema, { IMarkupSchemas } from 'components/SEOMarkupSchema'
import { SkeletonSelector } from 'components/skeleton'
import { UserSettings } from 'components/UserSettings'

import { envUtil } from 'utils'
import { linkResolver, repositoryName } from 'env/prismicio'

import '@fontsource/poppins/300.css'
import '@fontsource/poppins/400.css'
import '@fontsource/poppins/500.css'
import '@fontsource/poppins/600.css'
import '@fontsource/poppins/700.css'
import 'styles/DatePicker.scss'
import 'styles/flagpack.scss'
import 'styles/globals.scss'
import 'styles/reactToastify.scss'

const AppComponents = dynamic(() => import('components/AppComponents'), {
  ssr: false
})

const MyApp = ({ Component, pageProps }: AppProps<any>) => {
  const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FB_PIXEL_ID
  const FB_SITE_VERIFICATION = process.env.NEXT_PUBLIC_FB_SITE_VERIFICATION
  const DISABLE_INDEXING = process.env.NEXT_PUBLIC_DISABLE_INDEXING
  const isProd = process.env.NODE_ENV === 'production'
  const { captchaKey } = envUtil.getEnv()
  const { locale } = useRouter()
  const [queryClient] = useState(() => new QueryClient())

  return (
    <>
      {FB_PIXEL_ID && (
        <Script
          id="fb-pixel"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
                  !function(f,b,e,v,n,t,s)
                  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '${FB_PIXEL_ID}');
                    fbq('track', 'PageView');
                  `
          }}
        />
      )}
      {captchaKey && (
        <Script
          id="reCaptchaScriptId"
          src={`https://www.google.com/recaptcha/api.js?render=explicit&hl=${locale}`}
        />
      )}
      {pageProps.markupSchema?.length &&
        (pageProps.markupSchema as IMarkupSchemas).map((schemaProps, index) => (
          <SEOMarkupSchema key={index} {...schemaProps} />
        ))}
      <PrismicProvider
        linkResolver={linkResolver}
        internalLinkComponent={(props) => <Link {...props} />}
      >
        <PrismicPreview repositoryName={repositoryName}>
          <QueryClientProvider client={queryClient}>
            <MetaTags
              disableIndexing={
                DISABLE_INDEXING || pageProps?.seo?.disableIndexing
              }
              gsv={process.env.NEXT_PUBLIC_GSV}
              fbSiteVerification={FB_SITE_VERIFICATION}
              isProd={isProd}
              {...pageProps.seo}
            />
            <SessionProvider
              session={pageProps.internal?.session}
              refetchInterval={5 * 60}
              refetchOnWindowFocus
            >
              <UserSettings>
                <AppComponents />
                <AuthGates
                  auth={pageProps.internal?.auth}
                  isModal={!!pageProps.internal?.useModal}
                >
                  <FirebaseAuth>
                    <Layout {...pageProps.internal?.layout}>
                      <SkeletonSelector {...pageProps.internal?.useModal}>
                        <Component {...pageProps} />
                      </SkeletonSelector>
                    </Layout>
                  </FirebaseAuth>
                </AuthGates>
              </UserSettings>
            </SessionProvider>
          </QueryClientProvider>
        </PrismicPreview>
      </PrismicProvider>
    </>
  )
}

export default appWithTranslation<any>(MyApp)
